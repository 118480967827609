import Layout from '@/layout';
var domainRouter = {
  path: '/domain',
  component: Layout,
  redirect: '/domain',
  alwaysShow: true,
  // will always show the root menu
  name: 'M_010',
  meta: {
    title: '域名中心',
    icon: 'system'
  },
  children: [{
    path: '/domain/index',
    component: function component() {
      return import('@/views/domain/index');
    },
    name: 'M_010_001',
    meta: {
      title: '域名管理',
      icon: 'vpn',
      noCache: true
    }
  }, {
    path: '/domain/category',
    component: function component() {
      return import('@/views/domain_category/index');
    },
    name: 'M_010_002',
    meta: {
      title: '商品分类',
      icon: 'vpn',
      noCache: true
    }
  }, {
    path: '/domain/loadpage',
    component: function component() {
      return import('@/views/domain_loadpage/index');
    },
    name: 'M_010_003',
    meta: {
      title: '模板管理',
      icon: 'vpn',
      noCache: true
    }
  }, {
    path: '/domain/loadpage/posts',
    component: function component() {
      return import('@/views/domain_loadpage/content_list');
    },
    name: 'M_010_005',
    meta: {
      title: '导购内容',
      icon: 'vpn',
      noCache: true
    }
  }, {
    path: '/domain/loadpage/posts/form',
    component: function component() {
      return import('@/views/domain_loadpage/content_form');
    },
    name: 'M_010_006',
    hidden: true,
    meta: {
      title: '内容编辑',
      icon: 'vpn',
      noCache: true
    }
  }, {
    path: '/domain/offer-sku',
    component: function component() {
      return import('@/views/domain_offer_sku/index');
    },
    name: 'M_010_004',
    meta: {
      title: 'Offer商品',
      icon: 'vpn',
      noCache: true
    }
  }, {
    path: '/league/goods',
    component: function component() {
      return import('@/views/union/offer');
    },
    name: 'M_010_007',
    meta: {
      title: '联盟Offer',
      icon: 'vpn',
      noCache: true
    }
  }, {
    path: '/domain/union',
    component: function component() {
      return import('@/views/union/index');
    },
    name: 'M_010_008',
    meta: {
      title: '联盟管理',
      icon: 'vpn',
      noCache: true
    }
  }]
};
export default domainRouter;