import Layout from '@/layout';
var removeBgRouter = {
  path: '/removeBg',
  component: Layout,
  redirect: '/removeBg',
  alwaysShow: true,
  // will always show the root menu
  name: 'M_012',
  meta: {
    title: '图片筛选管理',
    icon: 'operation'
  },
  children: [{
    path: '/removebg/filter',
    component: function component() {
      return import('@/views/removebg/filter');
    },
    name: 'M_012_001',
    meta: {
      title: '图片筛选',
      icon: 'upload',
      noCache: true
    }
  }, {
    path: '/removebg/packlist',
    component: function component() {
      return import('@/views/removebg/packlist');
    },
    name: 'M_012_002',
    meta: {
      title: '打包列表',
      icon: 'order',
      noCache: true
    }
  }, {
    path: '/removebg/transparent',
    component: function component() {
      return import('@/views/removebg/transparent');
    },
    name: 'M_012_003',
    meta: {
      title: '透明图片筛选',
      icon: 'upload',
      noCache: true
    }
  }, {
    path: '/removebg/test_useful',
    component: function component() {
      return import('@/views/removebg/useTest');
    },
    name: 'M_012_004',
    meta: {
      title: '合格图片测试',
      icon: 'upload',
      noCache: true
    }
  }, {
    path: '/removebg/test_inv',
    component: function component() {
      return import('@/views/removebg/invTest');
    },
    name: 'M_012_005',
    meta: {
      title: '不合格图片测试',
      icon: 'upload',
      noCache: true
    }
  }]
};
export default removeBgRouter;