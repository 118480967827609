import request from '@/utils/request';
export function login(data) {
  return request({
    url: '/auth/login',
    method: 'post',
    data: data
  });
}
export function getInfo(token) {
  return request({
    url: '/auth/user/info',
    method: 'get',
    params: {
      token: token
    }
  });
}
export function logout() {
  return request({
    url: '/auth/logout',
    method: 'post'
  });
}
// 用户列表
export function getUsers(data) {
  return request({
    url: '/permission/user',
    method: 'get',
    params: data
  });
}
// 新增用户
export function createUser(data) {
  return request({
    url: '/permission/user',
    method: 'post',
    data: data
  });
}
// 修改用户
export function updateUser(id, data) {
  return request({
    url: "/permission/user/".concat(id),
    method: 'put',
    data: data
  });
}
// 删除用户
export function deleteUser(id) {
  return request({
    url: "/permission/user/".concat(id),
    method: 'delete'
  });
}
// 用户详情
export function showUser(id) {
  return request({
    url: "/permission/user/".concat(id),
    method: 'get'
  });
}
export function setProfile(data) {
  return request({
    url: '/auth/profile',
    method: 'put',
    data: data
  });
}

/**
 * 更新管理员用户的状态
 *
 * @export
 * @param {integer} id
 * @param {string} status
 * @returns
 */
export function changeStatus(id, status) {
  return request({
    url: "/permission/user/".concat(id, "/status"),
    method: 'put',
    data: {
      status: status
    }
  });
}