import Layout from '@/layout';
var fbuserRouter = {
  path: '/operation',
  component: Layout,
  redirect: '/operation/account',
  alwaysShow: true,
  name: 'M_008',
  meta: {
    title: 'FB账户中心',
    icon: 'operation'
  },
  children: [{
    path: '/operation/account-source',
    component: function component() {
      return import('@/views/account/source_log');
    },
    name: 'M_008_004',
    meta: {
      title: '导入记录',
      icon: 'account',
      noCache: true
    }
  }, {
    path: '/operation/account',
    component: function component() {
      return import('@/views/account/index');
    },
    name: 'M_008_001',
    meta: {
      title: '账号管理',
      icon: 'account',
      noCache: true
    }
  }, {
    path: '/operation/account/:id',
    component: function component() {
      return import('@/views/account/show');
    },
    name: 'M_008_002',
    hidden: true,
    meta: {
      title: '账号详细',
      icon: 'account',
      noCache: true
    }
  }, {
    path: '/operation/account/import/:id/detail',
    component: function component() {
      return import('@/views/account/import_details');
    },
    name: 'M_008_005',
    hidden: true,
    meta: {
      title: '导入明细',
      icon: 'account',
      noCache: true
    }
  }, {
    path: '/operation/account-type',
    component: function component() {
      return import('@/views/account/account_type');
    },
    name: 'M_008_003',
    meta: {
      title: '账号类型',
      icon: 'account',
      noCache: true
    }
  }]
};
export default fbuserRouter;