import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import store from '@/store';

/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
export default function checkPermission(value) {
  if (value && value instanceof Array && value.length > 0) {
    var btns = store.getters && store.getters.btns;
    var hasPermission = btns.includes(value);
    if (!hasPermission) {
      return false;
    }
    return true;
  } else {
    return false;
  }
}