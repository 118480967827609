import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.number.constructor";
// import parseTime, formatTime and set to filter
export { parseTime, formatTime } from '@/utils';

/**
 * @param {number} time
 */
export function timeAgo(time) {
  var between = Date.now() / 1000 - Number(time);
  if (between < 60) {
    return ~~(between % 60) + '秒前';
  } else if (between < 3600) {
    if (between % 60 === 0) {
      return ~~(between / 60) + '分钟';
    }
    return ~~(between / 60) + '分钟' + ~~(between % 60) + '秒前';
  } else if (between < 86400) {
    if (between % 3600 === 0) {
      return ~~(between / 60) + '小时';
    }
    return ~~(between / 3600) + '小时' + ~~(between % 3600 / 60) + '分前';
  } else {
    return ~~(between / 86400) + ' 天前';
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  var si = [{
    value: 1e18,
    symbol: 'E'
  }, {
    value: 1e15,
    symbol: 'P'
  }, {
    value: 1e12,
    symbol: 'T'
  }, {
    value: 1e9,
    symbol: 'G'
  }, {
    value: 1e6,
    symbol: 'M'
  }, {
    value: 1e3,
    symbol: 'k'
  }];
  for (var i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol;
    }
  }
  return num.toString();
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, function (m) {
    return m.replace(/(?=(?!\b)(\d{3})+$)/g, ',');
  });
}

/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * 裁剪字符串
 *
 * @param {String} str
 * @param {number} len
 */
export function sub_string(str, len) {
  if (str.length > len) {
    return str.substring(0, len) + '...';
  }
  return str;
}

/**
 * 返回字典的字面量
 *
 * @param {String} key
 * @param {Array} option
 */
export function getLabel(key, option) {
  var key_name = 'value';
  var key_label = 'label';
  if (arguments.length === 4) {
    key_name = arguments[2];
    key_label = arguments[3];
  }
  var label;
  for (var i in option) {
    if (option[i][key_name] === key) {
      label = option[i][key_label];
      break;
    }
  }
  return label;
}