import Layout from '@/layout';
var serverRouter = {
  path: '/server',
  component: Layout,
  redirect: '/server',
  alwaysShow: true,
  // will always show the root menu
  name: 'M_005',
  meta: {
    title: '服务器管理中心',
    icon: 'system'
  },
  children: [{
    path: '/system/client/dashboard',
    component: function component() {
      return import('@/views/server/dashboard');
    },
    name: 'M_005_007',
    meta: {
      title: '节点监控',
      icon: 'vpn',
      noCache: true
    }
  }, {
    path: '/system/server',
    component: function component() {
      return import('@/views/server/server');
    },
    name: 'M_005_001',
    meta: {
      title: '服务器管理',
      icon: 'computer',
      noCache: true
    }
  }, {
    path: '/system/terminal',
    component: function component() {
      return import('@/views/server/client');
    },
    name: 'M_005_002',
    meta: {
      title: '节点管理',
      icon: 'computer',
      noCache: true
    }
  }, {
    path: '/system/proxy',
    component: function component() {
      return import('@/views/proxy/index');
    },
    name: 'M_005_003',
    meta: {
      title: '代理IP管理',
      icon: 'vpn',
      noCache: true
    }
  }, {
    path: '/system/client/cmd',
    component: function component() {
      return import('@/views/server/cmd');
    },
    name: 'M_005_004',
    meta: {
      title: '节点更新',
      icon: 'vpn',
      noCache: true
    }
  }, {
    path: '/system/client/cmd/detail/:id',
    component: function component() {
      return import('@/views/server/cmd_detail');
    },
    name: 'M_005_005',
    hidden: true,
    meta: {
      title: '节点更新',
      icon: 'vpn',
      noCache: true
    }
  }, {
    path: '/system/client/tags',
    component: function component() {
      return import('@/views/server/tags');
    },
    name: 'M_005_006',
    hidden: true,
    meta: {
      title: '节点标签',
      icon: 'vpn',
      noCache: true
    }
  }]
};
export default serverRouter;