import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/app/node_modules/core-js/modules/es6.array.iterator.js";
import "/app/node_modules/core-js/modules/es6.promise.js";
import "/app/node_modules/core-js/modules/es6.object.assign.js";
import "/app/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets

import Element from 'element-ui';
import "./styles/element-variables.scss";
import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import "./icons"; // icon
import "./permission"; // permission control
import "./utils/error-log"; // error log
import checkPermission from "./utils/permission"; // btn permission

import * as filters from "./filters"; // global filters

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */
Vue.use(Element, {
  size: Cookies.get('size') || 'mini' // set element-ui default size
});

// register global utility filters
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;
Vue.prototype.can = checkPermission;
store.dispatch('conf/initConf');
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});