import axios from 'axios';
import { MessageBox, Message } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';
// create an axios instance
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 45000 // request timeout
});

console.log(process.env);

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent

  if (store.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers['authorization'] = 'bearer ' + getToken();
  }
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;

  // 如果errcode 不为0的话则说明执行错误
  if (res.errcode !== 0) {
    /**
     * 暂时不需要劫持返回
    Message({
      message: res.msg || 'Error',
      type: 'error',
      duration: 5 * 1000
    })
    */

    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    if (res.errcode === 50008 || res.errcode === 50012 || res.errcode === 50014) {
      // to re-login
      MessageBox.confirm('您时间未操作，系统帮您自动退出, 您可以选择取消保留当前页面, 或者重新登录', '确认重新登录', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        store.dispatch('user/resetToken').then(function () {
          location.reload();
        });
      });
    }
    console.log(res);
    return res;
    // return Promise.reject(res)
  } else {
    return res;
  }
}, function (error) {
  console.log('err' + error); // for debug
  Message({
    message: error.msg,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
export default service;