import Layout from '@/layout';
var userorderRouter = {
  path: '/order',
  component: Layout,
  redirect: '/userorder',
  alwaysShow: true,
  name: 'M_003',
  meta: {
    title: '交号系统',
    icon: 'system'
  },
  children: [{
    path: '/users',
    component: function component() {
      return import('@/views/user/index');
    },
    name: 'M_003_001',
    meta: {
      title: '用户管理',
      icon: 'currency',
      noCache: true
    }
  }, {
    path: '/organization',
    component: function component() {
      return import('@/views/user/organization');
    },
    name: 'M_003_007',
    meta: {
      title: '公司管理',
      icon: 'tree',
      noCache: true
    }
  }, {
    path: '/company/group',
    component: function component() {
      return import('@/views/user/group');
    },
    name: 'M_003_008',
    meta: {
      title: '公司分组',
      icon: 'tree',
      noCache: true
    }
  }, {
    path: '/userorder',
    component: function component() {
      return import('@/views/userorder/index');
    },
    name: 'M_003_002',
    meta: {
      title: '交易订单管理',
      icon: 'international',
      noCache: true
    }
  }, {
    path: '/userorder/feed',
    component: function component() {
      return import('@/views/userorder/feed');
    },
    name: 'M_003_003',
    meta: {
      title: '异常反馈管理',
      icon: 'currency',
      noCache: true
    }
  }, {
    path: '/operation/app/version',
    component: function component() {
      return import('@/views/app/index');
    },
    name: 'M_003_004',
    meta: {
      title: '版本管理',
      icon: 'excel',
      noCache: true
    }
  }, {
    path: '/system/active-code',
    component: function component() {
      return import('@/views/activecode/index');
    },
    name: 'M_003_005',
    meta: {
      title: '激活码管理',
      icon: 'excel',
      noCache: true
    }
  }, {
    path: '/system/devices',
    component: function component() {
      return import('@/views/devices/index');
    },
    name: 'M_003_006',
    meta: {
      title: '设备管理',
      icon: 'excel',
      noCache: true
    }
  }]
};
export default userorderRouter;