import Layout from '@/layout';
var settlementRouter = {
  path: '/settlement',
  component: Layout,
  redirect: '/settlement/coin',
  alwaysShow: true,
  name: 'M_004',
  meta: {
    title: '结算中心',
    icon: 'system'
  },
  children: [{
    path: '/settlement/coin',
    component: function component() {
      return import('@/views/user/coin');
    },
    name: 'M_004_001',
    meta: {
      title: '交号账户管理',
      icon: 'currency',
      noCache: false
    }
  }]
};
export default settlementRouter;