import { getConf } from '@/api/system';
var state = {
  dict: {}
};
var mutations = {
  RESET_DICT: function RESET_DICT(state, conf) {
    state.dict = conf;
  },
  CLEAR_DICT: function CLEAR_DICT(state) {
    state.dict = {};
  }
};
var actions = {
  initConf: function initConf(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      getConf().then(function (response) {
        var data = response.data;
        commit('RESET_DICT', data);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  clearConf: function clearConf(_ref2) {
    var commit = _ref2.commit;
    commit('CLEAR_ERROR_LOG');
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};