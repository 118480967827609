import Layout from '@/layout';
var permissionRouter = {
  path: '/permission',
  component: Layout,
  redirect: '/system/role',
  alwaysShow: true,
  name: 'M_002',
  meta: {
    title: '权限管理',
    icon: 'permission'
  },
  children: [{
    path: '/system/role',
    component: function component() {
      return import('@/views/system/role');
    },
    name: 'M_002_001',
    meta: {
      title: '角色管理',
      icon: 'role',
      noCache: true
    }
  }, {
    path: '/system/users',
    component: function component() {
      return import('@/views/system/user');
    },
    name: 'M_002_002',
    meta: {
      title: 'ERP账号管理',
      icon: 'user',
      noCache: true
    }
  }, {
    path: '/system/organization',
    component: function component() {
      return import('@/views/organization/index');
    },
    name: 'M_002_003',
    meta: {
      title: '组织管理',
      icon: 'organization',
      noCache: true
    }
  }, {
    path: '/organization-limit/index',
    component: function component() {
      return import('@/views/organization-limit/index');
    },
    name: 'M_002_004',
    meta: {
      title: '公司权限管理',
      icon: 'organization',
      noCache: true
    }
  }]
};
export default permissionRouter;