import Layout from '@/layout';
var BiRouter = {
  path: '/bi',
  component: Layout,
  redirect: '/bi/facebook',
  alwaysShow: true,
  name: 'M_009',
  meta: {
    title: 'BI报表中心',
    icon: 'system'
  },
  children: [{
    path: '/bi/facebook',
    component: function component() {
      return import('@/views/bi/facebook');
    },
    name: 'M_009_001',
    meta: {
      title: '任务管理',
      icon: 'currency',
      noCache: false
    }
  }]
};
export default BiRouter;