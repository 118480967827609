import Layout from '@/layout';
var systemRouter = {
  path: '/system',
  component: Layout,
  redirect: '/system/country',
  alwaysShow: true,
  // will always show the root menu
  name: 'M_001',
  meta: {
    title: '系统管理',
    icon: 'system'
  },
  children: [{
    path: '/system/country',
    component: function component() {
      return import('@/views/system/country');
    },
    name: 'M_001_001',
    meta: {
      title: '国家管理',
      icon: 'international',
      noCache: true
    }
  }, {
    path: '/system/currency',
    component: function component() {
      return import('@/views/system/currency');
    },
    name: 'M_001_002',
    meta: {
      title: '货币管理',
      icon: 'currency',
      noCache: true
    }
  }, {
    path: '/system/notice',
    component: function component() {
      return import('@/views/notice/index');
    },
    name: 'M_001_003',
    meta: {
      title: '通知管理',
      icon: 'messsage',
      noCache: true
    }
  }, {
    path: '/system/monitor',
    component: function component() {
      return import('@/views/monitor/index');
    },
    name: 'M_001_004',
    meta: {
      title: '监控管理',
      icon: 'currency',
      noCache: true
    }
  }]
};
export default systemRouter;