import Layout from "@/layout";
var otAccountRouter = {
  path: "/ot_account",
  component: Layout,
  redirect: "/ot_account",
  alwaysShow: true,
  // will always show the root menu
  name: "M_011",
  meta: {
    title: "Facebook账号管理",
    icon: "operation"
  },
  children: [
  // {
  //     path: '/ot_account/index',
  //     component: () => import('@/views/ot_account/index'),
  //     name: 'M_011_002',
  //     meta: { title: '账号列表', icon: 'order', noCache: true }
  // },
  {
    path: "/ot_account/import",
    component: function component() {
      return import("@/views/ot_account/import");
    },
    name: "M_011_002",
    meta: {
      title: "账号导入分配",
      icon: "upload",
      noCache: true
    }
  }, {
    path: "/ot_account/group",
    component: function component() {
      return import("@/views/ot_account/group");
    },
    name: "M_011_003",
    meta: {
      title: "分组管理",
      icon: "order",
      noCache: true
    }
  }, {
    path: "/ot_account/feed_log",
    component: function component() {
      return import("@/views/ot_account/feed_log");
    },
    name: "M_011_004",
    meta: {
      title: "反馈记录",
      icon: "order",
      noCache: true
    }
  }, {
    path: "/ot_account/allot_log",
    component: function component() {
      return import("@/views/ot_account/allot_log");
    },
    name: "M_011_005",
    meta: {
      title: "分配记录",
      icon: "order",
      noCache: true
    }
  }]
};
export default otAccountRouter;