import request from '@/utils/request';
export function getConf(data) {
  return request({
    url: '/system/conf',
    method: 'get',
    params: data
  });
}

/**
 * 导出数据
 *
 * @export
 * @param {string} query
 * @returns
 */
export function exportData(query) {
  return request({
    url: '/system/export',
    method: 'get',
    params: query
  });
}
export function exportCheck(id) {
  return request({
    url: "/system/export/".concat(id),
    method: 'get'
  });
}
export function paymentConf() {
  return request({
    url: '/system/conf/payment',
    method: 'get'
  });
}