import Layout from '@/layout';
var contentRouter = {
  path: '/content',
  component: Layout,
  redirect: '/content',
  alwaysShow: true,
  name: 'M_006',
  meta: {
    title: '素材库中心',
    icon: 'operation'
  },
  children: [{
    path: '/content',
    component: function component() {
      return import('@/views/material/index');
    },
    name: 'M_006_001',
    meta: {
      title: '素材管理',
      icon: 'material',
      noCache: true
    },
    children: []
  }, {
    path: '/content/create',
    component: function component() {
      return import('@/views/material/create');
    },
    name: 'M_006_002',
    hidden: true,
    meta: {
      title: '新增素材',
      icon: 'excel',
      noCache: true
    }
  }, {
    path: '/content/edit/:id',
    component: function component() {
      return import('@/views/material/create');
    },
    name: 'M_006_003',
    hidden: true,
    meta: {
      title: '编辑素材',
      icon: 'excel',
      noCache: true
    }
  }]
};
export default contentRouter;