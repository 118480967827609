import Layout from '@/layout';
var taskRouter = {
  path: '/task',
  component: Layout,
  redirect: '/system/task',
  alwaysShow: true,
  name: 'M_007',
  meta: {
    title: '任务中心',
    icon: 'operation'
  },
  children: [{
    path: '/system/task',
    component: function component() {
      return import('@/views/tasks/index');
    },
    name: 'M_007_001',
    meta: {
      title: '任务监控管理',
      icon: 'task',
      noCache: true
    }
  }, {
    path: '/system/template/action',
    component: function component() {
      return import('@/views/system/template_action_index');
    },
    name: 'M_007_002',
    meta: {
      title: '动作管理',
      icon: 'template',
      noCache: true
    }
  }, {
    path: '/system/template/action/create',
    component: function component() {
      return import('@/views/system/template_action_form');
    },
    name: 'M_007_005',
    hidden: true,
    meta: {
      title: '新增动作',
      icon: 'template',
      noCache: true
    }
  }, {
    path: '/system/template/action/:id/edit',
    component: function component() {
      return import('@/views/system/template_action_form');
    },
    name: 'M_007_006',
    hidden: true,
    meta: {
      title: '编辑动作',
      icon: 'template',
      noCache: true
    }
  }, {
    path: '/operation/template/sets',
    component: function component() {
      return import('@/views/templatesets/index');
    },
    name: 'M_007_003',
    meta: {
      title: '任务模板管理',
      icon: 'excel',
      noCache: true
    }
  }, {
    path: '/operation/demands',
    component: function component() {
      return import('@/views/demands/index');
    },
    name: 'M_007_004',
    meta: {
      title: '排期管理',
      icon: 'excel',
      noCache: true
    }
  }, {
    path: '/operation/demand/plan',
    component: function component() {
      return import('@/views/demands/plan');
    },
    name: 'M_007_007',
    hidden: true,
    meta: {
      title: '排期明细',
      icon: 'excel',
      noCache: true
    }
  }, {
    path: '/system/task/log',
    component: function component() {
      return import('@/views/tasks/tasklog');
    },
    name: 'M_007_008',
    hidden: true,
    meta: {
      title: '任务日志',
      icon: 'user',
      noCache: true
    }
  }, {
    path: '/system/demands/form',
    component: function component() {
      return import('@/views/demands/form');
    },
    name: 'M_007_009',
    hidden: true,
    meta: {
      title: '编辑排期',
      icon: 'user',
      noCache: false
    }
  }, {
    path: '/system/task/chargelog',
    component: function component() {
      return import('@/views/tasks/chargelog');
    },
    name: 'M_007_010',
    meta: {
      title: '充值记录',
      icon: 'user',
      noCache: true
    }
  }]
};
export default taskRouter;